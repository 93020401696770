
import {Component, Vue, Prop} from 'vue-property-decorator';
import {State} from "vuex-class";
import {Position} from "@/types";
import throttle from "lodash.throttle";
import axios from "axios";

const DEFAULT_ITEM_SIZE = 10;
const DEFAULT_ITEM_EXTRA_SIZE = 10;

const DOUBLETAP_LIMIT_TIME = 600;   // en ms
const DOUBLETAP_LIMIT_SIZE = 10;    // en pixels

@Component({
    name: "InteractiveMap"
})
export default class InteractiveMap extends Vue {
    // props
    @Prop(Object) parameters!: any;

    @State("playerState") playerState!: any;
    @State("gameState") gameState!: any;
    @State("currentSessionId") currentSessionId!: string;

    // data
    imageWidth = 0;
    imageHeight = 0;
    scale = 1;
    minScale = 0;
    offset:Position = {x: 0, y: 0};
    containerWidth = 0;
    containerHeight = 0;

    interaction: any = null;
    lastTap:any = {
        time: 0,
        x: 0,
        y: 0
    };

    throttleEmit: any = null;
    touchEventData: any = null;

    margin: Position = {x: 0, y: 0};

    // computed
    get map(): string {
        return (this.parameters && this.parameters.map)? this.parameters.map:"default";
    }

    get movable(): boolean {
        return this.parameters? (this.parameters.movable === true):false;
    }

    get zoomable(): boolean {
        return this.parameters? (this.parameters.zoomable === true):false;
    }

    get mapPath(): string {
        if(this.parameters && this.parameters.maps) {
            return this.parameters.maps[this.map];
        }
        else {
            return this.map;
        }
    }
    get containerStyle(): any {
        return {
            left: this.offset.x.toFixed(0) + "px",
            top: this.offset.y.toFixed(0) + "px",
            width: (this.imageWidth * this.scale).toFixed(0) + "px",
            height: (this.imageHeight * this.scale).toFixed(0) + "px"
        };
    }

    get items(): Array<any> {
        let items: any = {};
        if(this.gameState && this.gameState.items && Array.isArray(this.gameState.items)) {
            this.gameState.items
                .filter((item:any) => (item.x !== undefined && item.y !== undefined))
                .forEach((item:any) => {
                    items[item.itemId] = {
                        ...item,
                        x: item.x * this.imageWidth * this.scale,
                        y: item.y * this.imageHeight * this.scale
                    };
                });
                // type: (item.playerId === this.currentSessionId)? "mine":"other"
        }

        if(this.playerState && this.playerState.items && Array.isArray(this.playerState.items)) {
            this.playerState.items
                .forEach((item:any) => {
                    if(items[item.itemId]) {
                        items[item.itemId] = {
                            ...items[item.itemId],
                            ...item,
                            x: (item.x !== undefined && item.x !== null) ? (item.x * this.imageWidth * this.scale) : items[item.itemId].x,
                            y: (item.y !== undefined && item.y !== null) ? (item.y * this.imageHeight * this.scale) : items[item.itemId].y
                        };
                    }
                    else {
                        items[item.itemId] = {
                            ...item,
                            x: item.x * this.imageWidth * this.scale,
                            y: item.y * this.imageHeight * this.scale
                        };
                    }
                });
        }

        return Object.values(items);
    }

    // methods
    mousedown(event: MouseEvent) {
        console.log("GAME State = ", this.gameState);
        this.interactStart({x: event.x - this.margin.x, y: event.y - this.margin.y});
    }

    mouseup(event: MouseEvent) {
        this.interactStop({x: event.x - this.margin.x, y: event.y - this.margin.y});
    }

    mousemove(event: MouseEvent) {
        this.interactMove({x: event.x - this.margin.x, y: event.y - this.margin.y});
    }

    touchstart(event: TouchEvent) {
        console.log("touch start: ", event);
        const first = {x: event.touches[0].clientX - this.margin.x, y: event.touches[0].clientY - this.margin.y};
        const second = (event.touches.length > 1)? {x: event.touches[1].clientX - this.margin.x, y: event.touches[1].clientY - this.margin.y}:undefined;

        this.interactStart(first, second);
    }

    touchmove(event: TouchEvent) {
        if(this.interaction) {
            // console.log("touch move: ", event);
            let first = {x: event.touches[0].clientX - this.margin.x, y: event.touches[0].clientY - this.margin.y};
            let second = (event.touches.length > 1) ? {
                x: event.touches[1].clientX - this.margin.x,
                y: event.touches[1].clientY - this.margin.y
            } : undefined;

            // simulate double touch
            // second = first;
            // first = {x: this.containerWidth / 2, y: this.containerHeight / 2};

            if (this.interaction.pinch && (second === undefined)) {
                this.interactStop();
                this.interactStart(first);
            }
            else {
                this.interactMove(first, second);
            }
        }
    }

    touchend(event: TouchEvent) {
        let first = (event.changedTouches.length > 0) ? {x: event.changedTouches[0].clientX - this.margin.x, y: event.changedTouches[0].clientY - this.margin.y} : undefined;
        let second = (event.changedTouches.length > 1) ? {x: event.changedTouches[1].clientX - this.margin.x, y: event.changedTouches[1].clientY - this.margin.y} : undefined;
        this.interactStop(first, second);
    }

    // --------------------------------------------------
    interactStart(first: Position, second?: Position) {
        console.log("interect start: ", first, second);

        // simulate double touch
        // second = first;
        // first = {x: this.containerWidth / 2, y: this.containerHeight / 2};

        this.sendTouchEvent("start", first, second);

        if(second) {
            console.log("pinch start");
            this.interaction = {
                start: {
                    first,
                    second
                },
                pinch: true,
                scaleStart: this.scale,
                offsetStart: {x: this.offset.x, y: this.offset.y},
                last: {
                    first,
                    second
                }
            };
        }
        else {
            const tapNow = Date.now();
            if((tapNow - this.lastTap.time <= DOUBLETAP_LIMIT_TIME) && (Math.abs(first.x - this.lastTap.x) <= DOUBLETAP_LIMIT_SIZE) && (Math.abs(first.y - this.lastTap.y) <= DOUBLETAP_LIMIT_SIZE)) {
                this.lastTap = {time: 0, x: 0, y: 0};
                console.log("double tap");
                this.interactDoubleTap(first);
                // console.log("Game State: ", this.gameState);
                // console.log("ITEMS: ", this.items);
            }
            else {
                this.lastTap = {time: tapNow, x: first.x, y: first.y};
                this.interaction = {
                    start: first,
                    offsetStart: this.offset,
                    last: first
                };
                // console.log("simple tap");
                this.interactTap(first);
            }
        }

        console.log("interaction: ", this.interaction);
    }

    interactMove(first: Position, second?: Position) {
        this.sendTouchEvent("move", first, second);

        if(this.interaction) {
            if(second) {
                this.interaction.last = {first, second};

                if(this.zoomable) {
                    // console.log("container: ", this.containerWidth);
                    // console.log("second = ", this.interaction.start.second.x);
                    // console.log("first = ", this.interaction.start.first.x);
                    const startDiffX = this.interaction.start.second.x - this.interaction.start.first.x;
                    const startDiffY = this.interaction.start.second.y - this.interaction.start.first.y;
                    const startDist = Math.sqrt(startDiffX * startDiffX + startDiffY * startDiffY);
                    const startCenterX = (this.interaction.start.second.x - this.interaction.start.first.x) / 2 + this.interaction.start.first.x;
                    const startCenterY = (this.interaction.start.second.y - this.interaction.start.first.y) / 2 + this.interaction.start.first.y;

                    const nowDiffX = second.x - first.x;
                    const nowDiffY = second.y - first.y;
                    const nowDist = Math.sqrt(nowDiffX * nowDiffX + nowDiffY * nowDiffY);
                    const nowCenterX = (second.x - first.x) / 2 + first.x;
                    const nowCenterY = (second.y - first.y) / 2 + first.y;

                    const newScale = nowDist * this.interaction.scaleStart / startDist;

                    // console.log("sdiff = ", startDiffX);
                    // console.log("ediff = ", nowDiffX);
                    // console.log("original scale = ", this.interaction.scaleStart);
                    // console.log("new scale = " + newScale);

                    // compute new offset
                    let x = (nowCenterX * this.interaction.scaleStart - startCenterX * newScale + this.interaction.offsetStart.x * newScale) / this.interaction.scaleStart;
                    let y = (nowCenterY * this.interaction.scaleStart - startCenterY * newScale + this.interaction.offsetStart.y * newScale) / this.interaction.scaleStart;

                    this.changeScale(newScale);
                    this.changeOffset({x, y});
                }
            }
            else {
                this.interaction.last = first;
                if(this.movable) {
                    this.changeOffset({
                        x: this.interaction.offsetStart.x + this.interaction.last.x - this.interaction.start.x,
                        y: this.interaction.offsetStart.y + this.interaction.last.y - this.interaction.start.y
                    });
                }
            }
        }
    }

    interactStop(first?: Position, second?: Position) {
        this.interaction = null;
        this.sendTouchEvent("stop", first, second);
    }

    interactTap(position: Position) {
        // console.log("tap at ", position.x, position.y);
        // console.log("width = ", this.imageWidth);
        // console.log("scale = ", this.scale);
        // console.log("offset = ", this.offset.x, this.offset.y);
        // console.log("RESULT = ", (position.x - this.offset.x) / (this.imageWidth * this.scale), (position.y - this.offset.y) / (this.imageHeight * this.scale));
        this.$emit("tap", {
            x: (position.x - this.offset.x) / (this.imageWidth * this.scale),
            y: (position.y - this.offset.y) / (this.imageHeight * this.scale)
        });
    }

    interactDoubleTap(position: Position) {
        console.log("double tap at ", position);
        this.$emit("doubletap", {
            x: (position.x - this.offset.x) / (this.imageWidth * this.scale),
            y: (position.y - this.offset.y) / (this.imageHeight * this.scale)
        });
    }

    sendTouchEvent(type: string, first?: Position, second?: Position) {
        if(this.parameters && (this.parameters.touchEvents === true)) {
            let event: any = {type};
            if(first) {
                event.first = {
                    x: (first.x - this.offset.x) / (this.imageWidth * this.scale),
                    y: (first.y - this.offset.y) / (this.imageHeight * this.scale)
                };
            }
            if(second) {
                event.second = {
                    x: (second.x - this.offset.x) / (this.imageWidth * this.scale),
                    y: (second.y - this.offset.y) / (this.imageHeight * this.scale)
                };
            }

            if(type === "move") {
                this.touchEventData = event;
                this.throttleEmit();
            }
            else {
                this.$emit("touch_event", event);
            }
        }
    }

    changeOffset(newValue: Position) {
        console.log("Change offset: ", newValue.x, newValue.y);

        const width = this.containerWidth - this.imageWidth * this.scale;
        const xmin = Math.min(width / 2, width);
        const xmax = Math.max(0, (width) / 2);
        const x = Math.min(Math.max(newValue.x, xmin), xmax);

        const height = this.containerHeight - this.imageHeight * this.scale;
        const ymin = Math.min(height / 2, height);
        const ymax = Math.max(0, (height) / 2);
        const y = Math.min(Math.max(newValue.y, ymin), ymax);

        this.offset = {x, y};
        console.log("offset: ", this.offset.x, this.offset.y);
    }

    changeScale(newValue: number) {
        console.log("Change scale: ", newValue);
        this.scale = Math.max(newValue, this.minScale);
        console.log("scale: ", this.scale);
    }

    itemStyle(item: any): any {
        let style: any = {
            left: item.x.toFixed(0) + "px",
            top: item.y.toFixed(0) + "px",
            backgroundImage: "url(" + this.getItemImagePath(item) + ")"
        };

        let itemSize = this.getItemSize(item);
        let extraRatio = this.getItemExtraRatio(item);
        let extraItemSize = itemSize * extraRatio;
        style.width = style.height = extraItemSize.toFixed(0) + "px";
        return style;
    }

    innerItemStyle(item: any): any {
        let style: any = {};
        let itemSize = this.getItemSize(item);
        style.width = style.height = itemSize.toFixed(0) + "px";
        return style;
    }

    getItemSize(item: any): number {
        const itemType = this.getItemType(item.type);
        let size = item.size? item.size:((itemType && itemType.size)? itemType.size:DEFAULT_ITEM_SIZE);

        let itemSize = size * this.scale;

        if(itemType) {
            if(itemType.minSize && (itemType.minSize > itemSize)) {
                itemSize = itemType.minSize;
            }
            if(itemType.maxSize && (itemType.maxSize < itemSize)) {
                itemSize = itemType.maxSize;
            }
        }

        return itemSize;
    }

    getItemExtraRatio(item: any): number {
        const itemType = this.getItemType(item.type);
        let size = item.size? item.size:((itemType && itemType.size)? itemType.size:DEFAULT_ITEM_SIZE);
        let extraSize = item.extraSize? item.extraSize:((itemType && itemType.extraSize)? itemType.extraSize:DEFAULT_ITEM_EXTRA_SIZE);
        return extraSize / size;
    }

    getItemImagePath(item: any): any {
        if(this.parameters && this.parameters.itemTypes) {
            const type = item.type? item.type:"default";
            let config = this.parameters.itemTypes[type];
            if(config === undefined || config === null) {
                config = this.parameters.itemTypes.default;
            }
            if(config) {
                return config.path;
                // TODO SELECTED.
            }
        }

        return "";
    }

    getItemType(type: string): any {
        let config = null;
        if(this.parameters && this.parameters.itemTypes) {
            const realType = type? type : "default";
            config = this.parameters.itemTypes[realType];
            if(config === undefined || config === null) {
                config = this.parameters.itemTypes.default;
            }
        }

        return config;
    }

    itemClicked(item: any) {
        console.log("item clicked.");
        this.$emit("item_clicked", item);
    }

    computeMargins() {
        let elem = <HTMLElement>this.$el;
        let left = elem.offsetLeft;
        let top = elem.offsetTop;

        while(elem.offsetParent) {
            elem = <HTMLElement>elem.offsetParent;
            left += elem.offsetLeft;
            top += elem.offsetTop;
        }

        this.margin = {x: left, y: top};
        console.log("MARGINS: ", this.margin.x, this.margin.y);
    }

    init() {
        if(this.$el) {
            this.computeMargins();
            this.containerWidth = (<HTMLElement>this.$el).clientWidth;
            this.containerHeight = (<HTMLElement>this.$el).clientHeight;

            // compute min scale and offset.
            let s = 1;
            if (this.imageWidth !== this.containerWidth) {
                s = this.containerWidth / this.imageWidth;
            }
            if (this.imageHeight * s > this.containerHeight) {
                s = this.containerHeight / this.imageHeight;
            }
            this.minScale = s;

            if(this.parameters && (this.parameters.originalScale > 0)) {
                this.scale = this.parameters.originalScale;
            }
            else {
                this.scale = s;
            }

            if(this.parameters && this.parameters.originalOffset) {
                this.changeOffset({
                    x: this.parameters.originalOffset.x * this.imageWidth * this.scale,
                    y: this.parameters.originalOffset.y * this.imageHeight * this.scale
                });
            }
            else {
                if (this.imageHeight * this.scale > this.containerHeight) {
                    this.changeOffset({
                        x: (this.containerWidth - this.imageWidth * s) / 2,
                        y: 0
                    });
                }
                else {
                    this.changeOffset({
                        x: 0,
                        y: (this.containerHeight - this.imageHeight * s) / 2,
                    });
                }
            }
        }
    }

    async imageLoaded() {
        if(this.mapPath.endsWith(".svg")) {
            try {
                console.log("Loading SVG...");
                const result = await axios.get(this.mapPath);
                // console.log("RESULT DATA: ", result.data);
                const regex = /viewBox="([^"]*)"/i;
                const m = regex.exec(<string>result.data);
                if(m && (m.length === 2)) {
                    const viewBox = m[1].split(" ");
                    if(viewBox.length === 4) {
                        console.log("Image size from svg");
                        this.imageWidth = parseFloat(viewBox[2]);
                        this.imageHeight = parseFloat(viewBox[3]);
                        console.log("Image Size: ", this.imageWidth, " x ", this.imageHeight);
                    }
                }
                this.init();
            }
            catch(err) {
                console.error("cannot load SVG...", err);
            }
        }
        else {
            if(this.$refs.map) {
                const img = new Image;
                img.onload = () => {
                    this.imageWidth = img.width;
                    this.imageHeight = img.height;
                    console.log("Image Size: ", this.imageWidth, " x ", this.imageHeight);
                    this.init();
                };
                img.src =  (<HTMLImageElement>this.$refs.map).src;
            }
            else {
                console.error("cannot get img for size.");
            }
        }
    }

    // watch

    // hooks
    mounted() {
        this.throttleEmit = throttle(function () {
            // @ts-ignore
            if(this!.touchEventData) {
                // @ts-ignore
                this.$emit("touch_event", this!.touchEventData);
            }
        }, 250, { 'trailing': false });
    }
}
