

import {Component, Vue, Prop} from 'vue-property-decorator';
import Action from "@/components/Action.vue";
import {State} from "vuex-class";
import {Socket} from "socket.io-client";
import {SocketEvent} from "@/enum";
import throttle from "lodash.throttle";
import QrCode from "@/components/QrCode.vue";
import moment from "moment-timezone";

export enum AccelerometerMode {
    accelerationOnly = "accelerationOnly",
    movementOnly = "movementOnly",
    accelerationAndMovement = "accelerationAndMovement"
}

const DEFAULT_ATTACK = 1000;
const DEFAULT_SUSTAIN = 100;
const DEFAULT_RELEASE = 1000;
const DEFAULT_ACCELEROMETER_FREQUENCY = 100;
const DEFAULT_ACCELEROMETER_MODE = AccelerometerMode.movementOnly;
const DEFAULT_MOVEMENT_FREQUENCY = 1000;
const DEFAULT_MOVEMENT_THRESHOLD = 10;

@Component({
    name: "GameShaker",
    components: {QrCode, Action},
})
export default class GameShaker extends Vue {
    // props
    @Prop() data: any;
    @Prop(Object) socket!: Socket;

    // data
    @State("gameState") gameState!: any;
    @State("playerState") playerState!: any;
    @State("synchro") synchro!: any;

    lastFlash: number = 0;
    flash: any = null;

    noAcl: boolean = false;
    acceleration: any = null;
    accelerationNorme2: number = 0;
    accelerationNormeSum: number = 0;
    accelerationEventCount: number = 0;
    accelerationNormeMoyenne: number = 0;
    throttleAccelerationEmit: any = null;
    movementTimer: any = null;

    showExplanation = true;
    showQrCode = false;

    debug = false;
    flashHistory = "";
    realFlashHistory = "";

    // computed
    get shakingObjectStyle(): any {
        return {

        };
    }

    get flashStyle(): any {
        const level = (this.flash && this.flash.level)? this.flash.level:0;
        return {
            //transform: "translate(-50%, -50%) scale(" + level + ")"
            // transform: "translate(-50%, -50%) scale(1)"
            opacity: Math.max(Math.min(level, 1), 0)
            //opacity: 1
        };
    }

    get accelerometerFrequency(): number {
        return this.data.frequency? this.data.frequency:DEFAULT_ACCELEROMETER_FREQUENCY;
    }

    get movementFrequency(): number {
        return this.data.movementFrequency? this.data.movementFrequency:DEFAULT_MOVEMENT_FREQUENCY;
    }

    get accelerometerMode(): AccelerometerMode {
        return this.data.accelerometerMode? this.data.accelerometerMode:DEFAULT_ACCELEROMETER_MODE;
    }

    get isMovementEnabled(): boolean {
        return (this.accelerometerMode === AccelerometerMode.movementOnly) || (this.accelerometerMode === AccelerometerMode.accelerationAndMovement);
    }

    get isAccelerationEnabled(): boolean {
        return (this.accelerometerMode === AccelerometerMode.accelerationOnly) || (this.accelerometerMode === AccelerometerMode.accelerationAndMovement);
    }

    get movementThreshold(): number {
        return this.data.movementThreshold? this.data.movementThreshold:DEFAULT_MOVEMENT_THRESHOLD;
    }

    get movementThreshold2(): number {
        return this.movementThreshold * this.movementThreshold;
    }

    get serverTimeOffset(): number {
        return this.synchro.offset;
    }

    get offsetDetail(): string {
        return "client: " + this.synchro.client + "<br/>server: " + this.synchro.server + "<br/>offset: " + this.serverTimeOffset;
    }

    historyLastVal = 0;
    get nextFlashStart(): number|null {
        if(this.gameState && this.gameState.nextFlash) {
            const dateItems = this.gameState.nextFlash.start.split(",");
            if(dateItems.length >= 7) {
                const start = new Date(parseInt(dateItems[0]), parseInt(dateItems[1]) - 1, parseInt(dateItems[2]),
                    parseInt(dateItems[3]), parseInt(dateItems[4]), parseInt(dateItems[5]),
                    parseInt(dateItems[6])
                ).valueOf();
                const attack = this.gameState.nextFlash.attack? this.gameState.nextFlash.attack:DEFAULT_ATTACK;
                const val = start - attack;
                if(this.historyLastVal !== val) {
                    this.historyLastVal = val;
                    this.flashHistory = moment(val).format("HH:mm:ss.SSS") + "<br/>" + this.flashHistory;
                }
                return val;
            }
        }

        return null;
    }

    // methods
    initAccelerometer() {
        if(this.isAccelerationEnabled) {
            this.throttleAccelerationEmit = throttle(function () {
                // @ts-ignore
                if (this!.acceleration) {
                    // @ts-ignore
                    this.socket.emit(SocketEvent.action, {
                        action: "acceleration",
                        // @ts-ignore
                        x: this.acceleration.x,
                        // @ts-ignore
                        y: this.acceleration.y,
                        // @ts-ignore
                        z: this.acceleration.z
                    });
                }
            }, this.accelerometerFrequency, {'trailing': false});
        }

        if(this.isMovementEnabled) {
            this.movementTimer = setTimeout(this.checkMovement, this.movementFrequency);
        }


        // @ts-ignore
        if(DeviceMotionEvent.requestPermission) {
            // @ts-ignore
            DeviceMotionEvent.requestPermission().then((response: string) => {
                if (response === 'granted') {
                    console.log("Device Motion Granted.");
                    this.addAccelerometerListeners();
                } else {
                    console.log("DeviceMotion Permission response = ", response);
                    this.noAcl = true;
                }
            });
        }
        else {
            this.addAccelerometerListeners();
        }
    }

    addAccelerometerListeners() {
        // Add a listener to get smartphone acceleration
        // in the XYZ axes (units in m/s^2)
        window.addEventListener('devicemotion', (event) => {
            console.log("motion: ", event);
            if(event.acceleration) {
                this.acceleration = event.acceleration;

                if(this.isMovementEnabled) {
                    this.accelerationNorme2 = this.acceleration.x * this.acceleration.x + this.acceleration.y * this.acceleration.y + this.acceleration.z * this.acceleration.z;
                    this.accelerationNormeSum = this.accelerationNormeSum + Math.sqrt(this.accelerationNorme2);
                    this.accelerationEventCount = this.accelerationEventCount + 1;
                }

                if(this.isAccelerationEnabled) {
                    this.throttleAccelerationEmit();
                }
            }
        });
    }

    checkMovement() {
        this.movementTimer = null;

        this.accelerationNormeMoyenne = this.accelerationNormeSum / this.accelerationEventCount;

        const movement = this.accelerationNorme2 >= this.movementThreshold2;
        this.socket.emit(SocketEvent.action, {
            action: "acceleration",
            movement,
            x: this.accelerationNormeMoyenne
        });

        this.accelerationNormeSum = 0;
        this.accelerationEventCount = 0;

        this.movementTimer = setTimeout(this.checkMovement, this.movementFrequency);
    }

    printedNextFlash: number|null = null;

    animationFrame() {
        const now = Date.now();
        const serverNow = now - this.serverTimeOffset;
        if(this.nextFlashStart && (this.nextFlashStart !== this.lastFlash)) {
            // console.log("test flash: ", this.nextFlashStart, " / server now: ", serverNow,  "<? ", this.nextFlashStart <= serverNow);
            if(this.nextFlashStart <= serverNow) {
                this.startFlash(this.gameState.nextFlash, this.nextFlashStart, serverNow);
                this.printedNextFlash = null;
            }
            else {
                if(this.printedNextFlash !== this.nextFlashStart) {
                    console.log("Next Flash: ", this.gameState.nextFlash, this.nextFlashStart, new Date(this.nextFlashStart));
                    console.log("Flash in " + Math.round((this.nextFlashStart - serverNow) / 1000) + "s");
                    this.printedNextFlash = this.nextFlashStart;
                }
            }
        }

        if(this.flash) {
            if(serverNow > this.flash.end) {
                this.flash = null;
            }
            else {
                this.flash.level = this.getFlashLevel(serverNow);
            }
        }

        requestAnimationFrame(this.animationFrame);
    }

    getFlashLevel(timestamp:number): number {
        if(this.flash) {
            if (timestamp >= this.flash.end) {
                return 0;
            }
            else if (timestamp >= this.flash.release.start) {
                return 1 - (timestamp - this.flash.release.start) / this.flash.release.duration;
            }
            else if (timestamp >= this.flash.sustain.start) {
                return 1;
            }
            else if (timestamp >= this.flash.attack.start) {
                return (timestamp - this.flash.attack.start) / this.flash.attack.duration;
            }
            else {
                console.log("before...");
                return 0;
            }
        }
        else {
            return 0;
        }
    }

    initAnimation() {
        requestAnimationFrame(this.animationFrame);
    }

    startFlash(flash: any, timestamp: number, now: number) {
        this.lastFlash = timestamp;
        const attack = flash.attack? flash.attack:DEFAULT_ATTACK;
        const sustain = flash.sustain? flash.sustain:DEFAULT_SUSTAIN;
        const release = flash.release? flash.release:DEFAULT_RELEASE;
        const start = now;
        this.flash = {
            level: 0,
            attack: {start: start, duration: attack},
            sustain: {start: start + attack, duration: sustain},
            release: {start: start + attack + sustain, duration: release},
            start: start,
            end: start + attack + sustain + release
        };
        // console.log("FLASH: ", this.flash);
        this.realFlashHistory = moment(start).format("HH:mm:ss.SSS") + "<br/>" + this.realFlashHistory;
    }

    // watch

    // hooks
    mounted() {
        this.initAccelerometer();
        this.initAnimation();
    }

    beforeDestroy() {
        if(this.movementTimer) {
            clearTimeout(this.movementTimer);
            this.movementTimer = null;
        }
    }
}
